<template>
  <ContentfulRenderer
    class="the-footer-responsible-gaming text-caption text-center"
    :data="data?.items[0]?.responsibleGamingSection"
  />
</template>

<script lang="ts" setup>
const { data } = await useContentfulContent().getFooter()
</script>

<style lang="scss" scoped>
.the-footer-responsible-gaming {
  :deep(a) {
    display: inline-flex;
  }

  :deep(p) {
    + p {
      margin-top: spacing('xs');
    }
  }
}
</style>
